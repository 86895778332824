/* CSS Document */
@import 'mixins';

/* GENERAL */ 
html {
	body {
    footer {
      display: none;
    }
		section#main {
      article {
        height: 100%;
        min-height: 100vh;
        position: relative;
        &#intro {
          position: relative;
          .reel {
            display: block;
            height: 100%;
            left: 0;
            max-width: 100vw;
            min-height: 100vh;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
            img {
              min-height: 100vh;
            }
          }
          .container {
            height: 100%;
            margin: 0 auto;
            overflow: auto;
            position: relative;
          }
          .sidebar {
            width: 347px;
            #flag {
              background-image: url(../imgs/pic-flag.png);
              background-position: center -61px;
              height: 620px;
              padding: 0 40px;
              width: 100%;
              img#logo {
                height: auto;
                margin-top: 40px;
                max-width: 100%;
              }
              hr {
                border-top-color: $gray;
                margin-bottom: 30px;
                margin-top: 40px;
              }
              ul {
                display: block;
                list-style-type: none;
                margin: 0 auto;
                padding: 0;
                li {
                  display: block;
                  margin-bottom: 10px;
                  padding-left: 20px;
                  position: relative;
                  &:hover::before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 6px;
                    left: 0px;
                    transform: rotate(45deg);
                    width: 8px;
                    height: 8px;
                    border-top: 3px solid $purple;
                    border-right: 3px solid $purple;
                  }
                  a {
                    color: $font-dark2;
                    font-family: $text-special;
                    font-size: 20px;
                    &:hover {
                      color: $font-dark;
                    }
                  }
                }
              }
            }
            .nav-sm {
              display: block;
              margin: 0 auto 12px;
              text-align: center;
              ul {
                display: block;
                list-style-type: none;
                margin: 0 auto;
                padding: 0;
                li {
                  display: inline-block;
                  margin-right: 16px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
            .coming-events {
              text-align: center;
              img {
                height: auto;
                max-width: 100%;
              }
            }
          }
          .slogan {
            left: 432px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            span {
              color: $soft-violet;
              font-family: $text-content;
              display: block;
              width: 370px;
            }
            .top {
              font-weight: 300;
              font-size: 28px;
              letter-spacing: 5px;
              opacity: 0.6;
              text-align: left;
            }
            .bottom {
              font-size: 32px;
              font-style: italic;
              font-weight: 600;
              letter-spacing: 8px;
              opacity: 0.8;
              text-align: right;
            }
          }
        }
        &#b_services {
          background-color: $soft-violet;
          h2 {
            color: $gray-black;
            margin-bottom: 75px;
          }
          h3 {
            margin: 0 auto 16px;
          }
          .services {
            display: flex;
          }
          .service {
            margin-right: 8%;
            width: 28%;
            .service-img {
              margin: 0 auto 20px;
              text-align: right;
              img {
                height: 160px;
                width: auto;
              }
            }
            .more {
              a {
                color: $purple;
                font-family: $text-relevant;
                font-size: 16px;
              }
            }
          }
          .wrapper {
            margin: 0 auto 65px;
          }
        }
        &#b_about {
          background-color: #fff;
          h2 {
            margin-bottom: 60px;
          }
          /*#bottle-wrapper {
            display: none;
            position: absolute;
            #bottle {
              position: relative;
              width: 100%;
              img {
                height: auto;
                width: 100%;
              }
              #years {
                color: rgba(255, 255, 255, .9);
                font-family: $text-relevant;
                font-size: calc(100vw * .0375);
                right: 5.966%;
                position: absolute;
                text-align: center;
                top: 45.82%;
                width: 47.73%;
              }
            }
          }*/
          img#wood {
            height: auto;
            left: 0;
            position: absolute;
            bottom: 0px;
            width: 100%;
          }
          .about-content {
            font-size: 16pt;
            overflow: auto;
            padding: 40px 20px;
            position: relative;
            width: 100%;
            p {
              margin-bottom: 30px;
            }
            .img-left, .img-right, img {
              display: none;
            }
          }
          /*.middle-content {
            float: left;
            width: 65%;
            &:first-child {
              margin-top: 0;
            }
          }
          .right-content {
            float: right;
            width: 26%;
            img {
              height: auto;
              max-width: 100%;
            }
          }*/
          .wrapper {
            max-width: 1360px;
            .customers {
              padding-bottom: 8.2vw;
            }
          }
        }
        &.b_media {
          background-color: white;
          &:nth-of-type(2n + 2) {
            background-color: $soft-violet;
          }
          h2 {
            margin: 0px auto 90px;
          }
          .wrapper {
            h3 {
              font-size: 24px;
              margin-bottom: 20px;
            }
            .all-media {
              margin-top: 35px;
              text-align: center;
              a {
                background-image: url(../imgs/btn-link.gif);
                display: block;
                color: white;
                font-family: $text-relevant;
                font-size: 24px;
                height: 48px;
                margin: 0 auto;
                max-width: 80%;
                padding: 7px 0;
                text-align: center;
                text-transform: uppercase;
                width: 496px;
                &:hover {
                  color: rgba(255, 255, 255, .8);
                  text-decoration: none;
                }
              }
            }
            .content-mixed {
              display: flex;
              justify-content: space-between;
              margin-bottom: 85px;
              .cover {
                @include img-container(175px);
                display: inline-block;
                margin-right: 50px;
                width: 233px;
              }
              .content {
                display: inline-block;
                width: calc(100% - 183px);
              }
            }
            .content-text {
              margin-bottom: 85px;
              .content {
                display: block;
              }
            }
            .content-body {
              font-family: $text-content;
              font-size: 14px;
              margin-bottom: 14px;
            }
            .view-more {
              font-family: $text-relevant;
              font-size: 16px;
            }
          }
        }
        &#b_social-media {
          .sm-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            .sm-channel {
              width: 290px;
              margin-right: 40px;
              &:last-child {
                margin-right: 0;
              }
            }
            .inst-wrapper {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .instagram-title {
                display: block;
                text-align: center;
                width: 100%;
              }
              .inst-pic {
                width: 140px;
                img {
                  height: auto;
                  width: 100%;
                }
              }
            }
          }
          
        }
        &#b_contact {
          background-image: url(../imgs/img-cover-contact.jpg);
          background-size: cover;
          background-position: center center;
          a {
            color: $soft-violet;
            &:hover {
              color: inherit;
              text-decoration: underline;
            }
          }
          h2, h3, p, label {
            color: white;
          }
          h3 {
            font-size: 36px;
          }
          form {
            input, textarea {
              background-color: rgba(255, 255, 255, .7);
              border: none;
            }
          }
          .wrapper {
            max-width: 760px;
          }
          .contact-left {
            margin-bottom: 50px;
          }
        }
        h2 {
          font-size: 48px;
          text-align: center;
          margin: 0 auto 80px;
          padding-top: 60px;
        }
        h3 {
          color: $purple;
          font-size: 20px;
          margin: 0 auto 25px;
        }
        .wrapper {
          height: 100%;
          margin: 0 auto;
          max-width: 1280px;
          position: relative;
          width: 100%;
        }
      }
		}
    
    /* WIDESCREEN */
    @media (min-width: 992px) {
      section#main {
        article {
          /*&#about {
            #bottle-wrapper {
              bottom: 4.5%;
              display: inline;
              max-width: 27%;
            }
            .about-content {
              left: 33%;
              padding: 0;
              position: absolute;
              width: 67%;
            }
          }*/
          &#b_contact {
            .contact-half {
              display: inline-block;
              width: 47%;
              &:first-of-type {
                margin-right: 5%;
              }
            }
          }
        }
      }
    }

    /* TABLET AND WIDESCREEN */
    @media (min-width: 768px) {
      header {
        display: none;
        &.active {
          display: block;
        }
      }
      section#main {
        padding-top: 0;
        article {
          &#b_services {
            .service {
              &:last-child {
                margin-right: 0;
              }
            }
          }
          a.menu_anchor {
            position: absolute;
            top: -100px;
          }
        }
      }
    }

    /* TABLET AND MOBILE */
    @media (max-width: 991px) {
      section#main {
        article {
          /*&#about {
            .middle-content {
              &:last-child {
                width: 100%;
              }
            }
          }*/
        }
      }
    }

    /* MOBILE */
    @media (max-width: 767px) {
      section#main {
        article {
          &#b_intro {
            height: 75vw;
            min-height: 75vw !important;
            background-position: 80%;
            .reel {
              height: 75vw;
              min-height: 75vw;
              img {
                min-height: 75vw;
                max-width: 150%;
              }
            }
            .sidebar {
              display: none;
            }
          }
          &#b_about {
            .middle-content {
              margin-top: 30px;
            }
          }
          &#b_services {
            .services {
              display: block;
            }
            .service {
              margin: 20px auto;
              width: 80%;
            }
          }
          a.menu_anchor {
            position: absolute;
            top: -52px;
          }
        }
      }
    }
	}
}