/* SCSS Document */
/* VARIABLES */
$gray-dark: #1D1C1D;
$gray-black: #131313;
$gray: #6D6966;
$soft-violet: #E9E6DD;
$violet: #C8BEC7;
$purple: #4A2849;
$soft-purple: #6E536D;
$bg-light: #FFF;
$bg-medium: $soft-violet;
$bg-dark: $purple;
$font-lighter: #FFF;
$font-light: $soft-purple;
$font-medium: $purple;
$font-dark: $gray-dark;
$font-dark2: $gray-black;

/* FONTS */
$text-special: 'Ovo', serif;
$text-relevant: 'Cantata One', serif;
$text-content: 'Imprima', sans-serif;
$font-size-base: 10.5pt;

/*** MIXINS ***/
/* HORIZONTAL & VERTICAL CENTERING */
@mixin fullscreen_video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	transform: translate(-50%, -50%);
	background-size: cover; 
}
@mixin horizontal-center {
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
}
@mixin vertical-middle {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
@mixin absolute_middle {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%,-50%);
}
@mixin vertical-reset {
	top: 0px;
	transform: none;
}
@mixin img-container($h) {
  height: $h;
  overflow: hidden;
  position: relative;
  img {
    @include absolute_middle;
  }
  @media screen and (min-width: 992px) {
   img {
     height: auto;
     min-height: 100%;
     max-width: 100%;
     width: auto;
   }
  }
  @media screen and (max-width: 992px) {
    img {
      height: auto;
      width: auto;
      min-height: 100%;
      min-width: 100%;
      max-width: 120%;
    }
  }
}